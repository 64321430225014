//Codigo adaptado de https://devdactic.com/sqlite-ionic-app-with-capacitor/
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import '@capacitor-community/sqlite';
import { AlertController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JsonSQLite } from '@capacitor-community/sqlite';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
const { CapacitorSQLite, Device, Storage } = Plugins;
 
const DB_SETUP_KEY = 'first_db_setup';
const DB_NAME_KEY = 'db_name';

const apiUrl = environment.apiBaseUrl;
 
@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  dbReady = new BehaviorSubject(false);
  dbName = '';
 
  constructor(private http: HttpClient, private alertCtrl: AlertController, private sqlite: SQLite) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
 
  async init(): Promise<void> {
    const info = await Device.getInfo();
     // console.log(JSON.stringify('PLATAFORMA' + info));
    try {
      this.sqlite.create({
        name: 'data.db',
        location: 'default'
      }).then((db: SQLiteObject) => {

        /* db.executeSql('create table danceMoves(name VARCHAR(32))', [])
          .then(() =>  // console.log('Executed SQL'))
          .catch(e =>  // console.log(e));
        
        db.executeSql('insert into danceMoves(name) values ("Maria")', [])
          .then(() =>  // console.log('Executed SQL'))
          .catch(e =>  // console.log(e)); */


        let preventiveRoutineTable =
            'id INTEGER, ' +
            'preventive_consecutive INTEGER, ' +
            'preventive_routines_id INTEGER,' +
            'massice_preventive_maintenance_id INTEGER, ' +
            'forklift_id INTEGER,' +
            'customer_id INTEGER, ' +
            'branch_offices_id INTEGER, ' +
            'date VARCHAR(32), ' +
            'start VARCHAR(32), ' +
            'finish VARCHAR(32), ' +
            'status INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), ' +
            'work VARCHAR(32), ' +
            'type INTEGER, '+
            'sync INTEGER';
            

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_forklift_routines(${preventiveRoutineTable})`, [])
            .then(() => {
               // console.log('Executed SQL');
              /* db.executeSql('insert into preventive_forklift_routines  values (45929, 201181, 104, "", 45, 107, 17, "2020-11-30 14:27:00", "2020-11-23 10:48:11", "2020-11-23 11:08:45", 2, 0, "2020-11-30 14:27:37", "2020-12-23 11:13:05", "", 1)', [])
                .then(() =>  // console.log('Executed SQL'))
                .catch(e =>  // console.log(e)); */
            })
            .catch(e =>  console.log(e));

          let forkliftRoutinePreventiveTechnians =
            'id INTEGER, ' +
            'forklift_routine_id INTEGER, ' +
            'user_id INTEGER,' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS forklift_routine_preventive_technians(${forkliftRoutinePreventiveTechnians})`, [])
            .then()
            .catch(e =>  console.log(e));

            /* db.executeSql('insert into forklift_routine_preventive_technians values (729, 45929,323,0,"2020-11-30 14:27:38","2020-11-30 14:27:38")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */

          
          let preventiveMaintenanceDeliveryReview =
            'id INTEGER, ' +
            'forklift_routine_id INTEGER, ' +
            'observation VARCHAR(32),' +
            'status INTEGER, ' +
            'toilet_forms_id INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_maintenance_delivery_review(${preventiveMaintenanceDeliveryReview})`, [ ])
            .then()
            .catch(e =>  console.log(e));

          let toiletForms =
            'id INTEGER, ' +
            'delivery_review VARCHAR(32),' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), ' +
            'idel INTEGER, '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS toilet_forms (${toiletForms})`, [])
            .then(  )
            .catch(e =>  console.log(e));

          /* db.executeSql('insert into toilet_forms values (13,"HJBHJVHJM","2020-08-11 10:01:40", "2020-11-30 14:27:37", 0)', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into toilet_forms values (14,"JFSDNJM","2020-08-11 10:01:40", "2020-11-30 14:27:37", 0)', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into toilet_forms values (15,"ITEM2","2020-08-11 10:01:40", "2020-11-30 14:27:37", 0)', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into toilet_forms values (16,"UNIFORME LIMPIO","2020-08-11 10:01:40", "2020-11-30 14:27:37", 0)', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */

          let preventiveMaintenancePending =
            'id INTEGER, ' +
            'forklift_routine_id INTEGER, ' +
            'description VARCHAR(32),' +
            'quantity VARCHAR(32),' +
            'status INTEGER,' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_maintenance_pending(${preventiveMaintenancePending})`, [ ])
            .then()
            .catch(e =>   console.log(e));


          let preventiveMaintenanceSupplice =
            'id INTEGER, ' +
            'forklift_routine_id INTEGER, ' +
            'description VARCHAR(32),' +
            'quantity VARCHAR(32),' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_maintenance_supplice(${preventiveMaintenanceSupplice})`, [ ])
            .then( )
            .catch(e =>   console.log(e));

          let preventiveMakeRoutine =
            'id INTEGER, ' +
            'forklift_routine_id INTEGER, ' +
            'maintenance_routine_id INTEGER, ' +
            'maintenance_routine_description VARCHAR(32), ' +
            'system_routine_id INTEGER, ' +
            'system_routine_description VARCHAR(32), ' +
            'component_routine_id INTEGER, ' +
            'component_routine_description VARCHAR(32), ' +
            'parts_routine_id INTEGER, ' +
            'parts_routine_description VARCHAR(32), ' +
            'work VARCHAR(32), ' +
            'supplice VARCHAR(32), ' +
            'forklift_id INTEGER, ' +
            'parameters VARCHAR(32), ' +
            'check VARCHAR(32),' +
            'observation VARCHAR(32),' +
            'user_id INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_make_routine(${preventiveMakeRoutine})`, [ ])
            .then()
            .catch(e => console.log(e));

          let preventiveEmail =
            'id INTEGER, ' +
            'preventive_forklift_routines_id INTEGER, ' +
            'email VARCHAR(32), ' +
            'customer_id INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_email(${preventiveEmail})`, [ ])
            .then()
            .catch(e =>   console.log(e));

          
          let preventiveFirms =
            'id INTEGER, ' +
            'preventive_forklift_routines_id INTEGER, ' +
            'firm VARCHAR(32), ' +
            'name INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_firms(${preventiveFirms})`, [ ])
            .then()
            .catch(e =>  console.log(e));

          let preventiveImages =
            'id INTEGER, ' +
            'preventive_forklift_routines_id INTEGER, ' +
            'image VARCHAR(32), ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_images(${preventiveImages})`, [ ])
            .then()
            .catch(e =>   console.log(e));

          let preventiveConsecutive =
            'id INTEGER, ' +
            'consecutive INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_consecutive(${preventiveConsecutive})`, [])
            .then()
            .catch(e =>   console.log(e));

          /* db.executeSql('insert into preventive_consecutive values (1, 81, "2020-08-11 10:01:40", "2020-11-30 14:27:37", 0)', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */

          let preventiveRoutine =
            'id INTEGER, ' +
            'description VARCHAR(32), ' +
            'hours INTEGER, ' +
            'observation VARCHAR(32), ' +
            'type INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS preventive_routine(${preventiveRoutine})`, [])
            .then()
            .catch(e =>   console.log(e));

          /* db.executeSql('insert into preventive_routine values (104, "MTTO 250 HORAS", 3, "RUTINA PERIODICA DE 250 HORAS", "", 0, "2020-09-21 17:14:07", "2020-09-21 17:14:07")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */

          let componentRoutines =
            'id INTEGER, ' +
            'description VARCHAR(32), ' +
            'system_routine_id INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS component_routines(${componentRoutines})`, [])
            .then()
            .catch(e =>   console.log(e));

          /* db.executeSql('insert into component_routines values (89, "MOTOR HIDRAULICO", 106, 0, "2020-09-21 17:16:27", "2020-09-21 17:16:27")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into component_routines values (90, "MANGUERAS Y CONEXIONES", 106, 0, "2020-09-21 17:18:45", "2020-09-21 17:20:28")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into component_routines values (91, "PANEL DE CONTROL", 107, 0, "2020-09-21 17:22:12", "2020-09-21 17:22:12")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */


          
          let systemRoutines =
            'id INTEGER, ' +
            'preventive_routine_id INTEGER, ' +
            'maintenance_system_id INTEGER, ' +
            'system_description VARCHAR(32), ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS system_routines(${systemRoutines})`, [])
            .then()
            .catch(e =>   console.log(e));

          /* db.executeSql('insert into system_routines values (106, 104,128,"SISTEMA HIDRAULICO", 0, "2020-09-21 17:14:27", "2020-09-21 17:14:27")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into system_routines values (107, 104,129,"SISTEMA ELECTRICO", 0, "2020-09-21 17:21:53", "2020-09-21 17:21:53")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */

          let partsRoutines =
            'id INTEGER, ' +
            'component_routine_id INTEGER, ' +
            'description VARCHAR(32), ' +
            'work VARCHAR(32), ' +
            'supplice VARCHAR(32), ' +
            'parameter VARCHAR(32), ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS parts_routines(${partsRoutines})`, [])
            .then()
            .catch(e =>   console.log(e));


          /* db.executeSql('insert into parts_routines values ( 85, 89, "ESCOBILLAS", "REVISION, LIMPIEZA Y VERIFICACION", "DIELECTRICO", "N/A", 0, "2020-09-21 17:17:15", "2020-09-21 17:17:15")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into parts_routines values ( 86, 89, "RESOTRES", "REVISION, LIMPIEZA Y VERIFICACION", "DIELECTRICO", "N/A", 0, "2020-09-21 17:17:45", "2020-09-21 17:17:45")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));

          db.executeSql('insert into parts_routines values ( 87, 90, "MANGUERAS", "REVISION D EFUGAS Y REVESTIMIENTO DE MANGUERAS", "WYPALL - DESENGRASANTE", "N/A", 0, "2020-09-21 17:21:18", "2020-09-21 17:21:18")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e));


          db.executeSql('insert into parts_routines values ( 88, 91, "TERMINALES", "VERIFICACION VISUAL DE CONEXIONES", "DIELECTRICO", "N/A", 0, "2020-09-21 17:22:50", "2020-09-21 17:22:50")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */

          let forklift =
            'id INTEGER, ' +
            'serie VARCHAR(32), ' +
            'customer_id INTEGER, ' +
            'branch_offices_id INTEGER, ' +
            'customer_category VARCHAR(32), ' +
            'sequence VARCHAR(32), ' +
            'status INTEGER, ' +
            'brand_id INTEGER, ' +
            'model_id INTEGER, ' +
            'machine_id INTEGER, ' +
            'tyre_id INTEGER, ' +
            'tyre_forward VARCHAR(32), ' +
            'tyre_sback VARCHAR(32), ' +
            'fuel_id INTEGER, ' +
            'routine_id INTEGER, ' +
            'tonne INTEGER, ' +
            'mastil_izado INTEGER, ' +
            'mastil_contract INTEGER, ' +
            'h_initial INTEGER, ' +
            'h_current INTEGER, ' +
            'alarm VARCHAR(32), ' +
            'observation VARCHAR(32), ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS forklift(${forklift})`, [])
            .then()
            .catch(e => console.log(e));

          /* db.executeSql('insert into forklift values (45, "32456786674556", 107, 17, "", "", "EFVGWEVETRVW", "0", 15, 2, 6, 2, 1, "1", "77", 3, 1.000, 1.000, 1.000, 1.000, 38.000, 0, "arvqeawrvqerqe", 0, "2019-10-03 01:28:53", "2019-10-03 01:28:53")', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */


          let customer =
            'id INTEGER, ' +
            'business_name VARCHAR(32), ' +
            'type_document_id INTEGER, ' +
            'document_id INTEGER, ' +
            'telephone INTEGER, ' +
            'email VARCHAR(32), ' +
            'address VARCHAR(32), ' +
            'status INTEGER, ' +
            'price_margin INTEGER, ' +
            'payment_condition_id INTEGER, ' +
            'name VARCHAR(32), ' +
            'last_name VARCHAR(32), ' +
            'cellphone VARCHAR(32), ' +
            'department_id INTEGER, ' +
            'city_id INTEGER, ' +
            'idel INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS customer(${customer})`, [])
            .then()
            .catch(e =>   console.log(e));

          /* db.executeSql('insert into customer values (107, "HOMECENTER", 2, 78925632, 3125896123, "", "CALLE 10 #63 54", 0,24,1,"pedro", "osorio", "",1,2,"2019-09-10 17:33:26", "2019-09-10 17:33:26", 0)', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */


          let branchOffices =
            'id INTEGER, ' +
            'customer_id INTEGER, ' +
            'code INTEGER, ' +
            'branch_name VARCHAR(32), ' +
            'address VARCHAR(32), ' +
            'telephone VARCHAR(32), ' +
            'contact_name VARCHAR(32), ' +
            'contact_last_name VARCHAR(32), ' +
            'cellphone VARCHAR(32), ' +
            'email VARCHAR(32), ' +
            'department_id INTEGER, ' +
            'city_id INTEGER, ' +
            'latitude VARCHAR(32), ' +
            'longitude VARCHAR(32), ' +
            'idel INTEGER, ' +
            'status INTEGER, ' +
            'create_at VARCHAR(32), ' +
            'update_at VARCHAR(32), '+
            'sync INTEGER';

          db.executeSql(`CREATE TABLE IF NOT EXISTS branch_offices (${branchOffices})`, [])
            .then()
            .catch(e => console.log(e));

          /* db.executeSql('insert into branch_offices values (17, 107, "", "LA 65", "CALLE 85 #96 58", 5894565, "","","","", 1, 14, "", "", "2019-09-10 17:34:23", "2019-09-10 17:34:23", 0, 0)', [])
            .then(() =>  // console.log('Executed SQL'))
            .catch(e =>  // console.log(e)); */

            db.executeSql(`SELECT * from branch_offices`, [])
            .then()
            .catch(e =>   console.log(e));
 

        }).catch(e =>  console.log(e));
    } catch (error) {}
  }

  query(query){
    this.sqlite.create({
      name: 'data.db',
      location: 'default'
    }).then((db: SQLiteObject) => {

      db.executeSql(query, [])
      .then()
      .catch(e =>   console.log(e));

    }).catch(e =>  console.log(e));
  }

 /*
  private async setupDatabase() {
     // console.log('Antes del setup del metodo');
    const dbSetupDone = await Storage.get({ key: DB_SETUP_KEY });
     // console.log('SetupDataBase' + JSON.stringify(dbSetupDone));
    if (!dbSetupDone.value) {
      this.downloadDatabase();
    } else {
      this.dbName = (await Storage.get({ key: DB_NAME_KEY })).value;
      await CapacitorSQLite.open({ database: this.dbName });
      this.dbReady.next(true);
    }
  }
 
  // Potentially build this out to an update logic:
  // Sync your data on every app start and update the device DB
  private downloadDatabase(update = false) {
     'https://devdactic.fra1.digitaloceanspaces.com/tutorial/db.json' 
     `${apiUrl}` + '/api/get_sync_preventive_schema', this.httpOptions 
    this.http.get(`${apiUrl}` + '/api/get_sync_preventive_schema', this.httpOptions ).subscribe(async (jsonExport: JsonSQLite) => {
      const jsonstring = JSON.stringify(jsonExport);
      const isValid = await CapacitorSQLite.isJsonValid({ jsonstring });
       // console.log(jsonstring);
      
      if (isValid.result) {
        this.dbName = jsonExport.database;
        await Storage.set({ key: DB_NAME_KEY, value: this.dbName });
        await CapacitorSQLite.importFromJson({ jsonstring });
        await Storage.set({ key: DB_SETUP_KEY, value: '1' });
 
        // Your potential logic to detect offline changes later
        if (!update) {
          await CapacitorSQLite.createSyncTable();
        } else {
          await CapacitorSQLite.setSyncDate({ syncdate: '' + new Date().getTime() })
        }
        this.dbReady.next(true);
      }
    });
  }

  getProductList() {
    return this.dbReady.pipe(
      switchMap(isReady => {
        if (!isReady) {
          return of({ values: [] });
        } else {
          const statement = 'SELECT * FROM toilet_forms';
           // console.log(JSON.stringify(statement));
          return from(CapacitorSQLite.query({ statement, values: [] }));
        }
      })
    )
  }
   
  async getProductById(id) {
    const statement = `SELECT * FROM products LEFT JOIN vendors ON vendors.id=products.vendorid WHERE products.id=${id} ;`;
    return (await CapacitorSQLite.query({ statement, values: [] })).values[0];
  }
   
  getDatabaseExport(mode) {
    return CapacitorSQLite.exportToJson({ jsonexportmode: mode });
  }
   
  addDummyProduct(name) {
    const randomValue = Math.floor(Math.random() * 100) + 1;
    const randomVendor = Math.floor(Math.random() * 3) + 1
    const statement = `INSERT INTO products (name, currency, value, vendorid) VALUES ('${name}','EUR', ${randomValue}, ${randomVendor});`;
    return CapacitorSQLite.execute({ statements: statement });
  }
   
  deleteProduct(productId) {
    const statement = `DELETE FROM products WHERE id = ${productId};`;
    return CapacitorSQLite.execute({ statements: statement });
  }
   
  // For testing only..
  async deleteDatabase() {
    const dbName = await Storage.get({ key: DB_NAME_KEY });
    await Storage.set({ key: DB_SETUP_KEY, value: null });
    return CapacitorSQLite.deleteDatabase({ database: dbName.value });
  } */
}