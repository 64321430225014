import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { DatabaseService } from './services/databaseService/database.service';
import { NotificationsService } from './services/notificationsService/notifications.service';
import { SwUpdate } from '@angular/service-worker';
const { SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {

  public installPrompt : any;
  
  appPages = [
   {
     title: 'Categories',
     url: '/app/categories',
     ionicIcon: 'list-outline'
   },
   {
     title: 'Profile',
     url: '/app/user',
     ionicIcon: 'person-outline'
   },
   {
     title: 'Contact Card',
     url: '/contact-card',
     customIcon: './assets/custom-icons/side-menu/contact-card.svg'
   },
   {
     title: 'Notifications',
     url: '/app/notifications',
     ionicIcon: 'notifications-outline'
   }
 ];
 accountPages = [
   {
     title: 'Log In',
     url: '/auth/login',
     ionicIcon: 'log-in-outline'
   },
   {
     title: 'Sign Up',
     url: '/auth/signup',
     ionicIcon: 'person-add-outline'
   },
   {
     title: 'Tutorial',
     url: '/walkthrough',
     ionicIcon: 'school-outline'
   },
   {
     title: 'Getting Started',
     url: '/getting-started',
     ionicIcon: 'rocket-outline'
   },
   {
     title: '404 page',
     url: '/page-not-found',
     ionicIcon: 'alert-circle-outline'
   }
 ];

  constructor(
    private platform: Platform,
    private databaseService: DatabaseService,
    private notificationsService: NotificationsService,
    private swUpdate : SwUpdate
  ) {
    this.initializeApp();
    if(this.swUpdate.isEnabled){
    }
    if (this.swUpdate.available)
    {
      this.swUpdate.available.subscribe(() =>
      {
        if(confirm('Una nueva version esta disponible, ¿quieres recargarlo?'))
        {
          window.location.reload();
          
        }
      });
    }
  }

  async initializeApp() {
    try {
      this.platform.ready().then(async () => {
        this.notificationsService.initPush();
        /* this.databaseService.init(); */
        SplashScreen.hide();
        /* this.databaseService.dbReady.subscribe(isReady => {
          if (isReady) {
          }
        }); */
      });
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
  }

  getInstallPrompt() : void
  {
    window.addEventListener('beforeinstallprompt', (e) =>
    {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  askUserToInstallApp()
  {
    this.installPrompt.prompt();
  }
}
