export const environment = {
  production: false,
  name: "(DEV)",

  // apiBaseUrl:"https://backend-prodmaster.montacargasmaster.co",
  // clientId: '56',
  // clientSecret: 'cIzs4VP6ux19GaQVvPdBKfNUoz5ZBpre3jJDKB7J'

  //apiBaseUrl: "http://127.0.0.1:8000",
  //clientId: "66",
  //clientSecret: "QyEXhgrzp2neoFEf0ZvS3vTBkkRQXSU0fPQPP8Yc",

  apiBaseUrl: "https://backend-pruebas.montacargasmaster.co",
  clientId: "77",
  clientSecret: "r3ePl4dE3g6J590fT9MxoYutaNnAONxetPDQ5qse",
};
