import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/global-login",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "auth/signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupPageModule),
  },
  /* {
    path: 'auth/forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  }, */
  {
    path: "app",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },

  //{
  //  path: '**',
  //  redirectTo: 'page-not-found'
  //},
  {
    path: "auth/global-login",
    loadChildren: () =>
      import("./authentication/global-login/global-login.module").then(
        (m) => m.GlobalLoginPageModule
      ),
  },
  {
    path: "auth/forgot-password",
    loadChildren: () =>
      import(
        "./authentication/global-login-change-password/global-login-change-password.module"
      ).then((m) => m.GlobalLoginChangePasswordPageModule),
  },
  {
    path: "preventive-send-email-offline",
    loadChildren: () =>
      import(
        "./pagesOffline/preventive/preventive-send-email-offline/preventive-send-email-offline.module"
      ).then((m) => m.PreventiveSendEmailOfflinePageModule),
  },
  {
    path: "preventive-firm",
    loadChildren: () =>
      import("./pages/preventive/preventive-firm/preventive-firm.module").then(
        (m) => m.PreventiveFirmPageModule
      ),
  },
  {
    path: "inspections",
    loadChildren: () =>
      import("./inspections/inspections.module").then(
        (m) => m.InspectionsPageModule
      ),
  },

  {
    path: "questions",
    loadChildren: () =>
      import("./pages/questions/questions.module").then(
        (m) => m.QuestionsPageModule
      ),
  },
  {
    path: "answerlist",
    loadChildren: () =>
      import("./pages/answerlist/answerlist.module").then(
        (m) => m.AnswerlistPageModule
      ),
  },
  {
    path: "photo-chek-lis",
    loadChildren: () =>
      import("./pages/photo-chek-lis/photo-chek-lis.module").then(
        (m) => m.PhotoChekLisPageModule
      ),
  },
  {
    path: "evidence-answers",
    loadChildren: () =>
      import("./pages/evidence-answers/evidence-answers.module").then(
        (m) => m.EvidenceAnswersPageModule
      ),
  },
  {
    path: "response-signature",
    loadChildren: () =>
      import("./pages/response-signature/response-signature.module").then(
        (m) => m.ResponseSignaturePageModule
      ),
  },
  {
    path: "question-email",
    loadChildren: () =>
      import("./pages/question-email/question-email.module").then(
        (m) => m.QuestionEmailPageModule
      ),
  },
  // {
  //   path: 'new',
  //   loadChildren: () => import('./page/new/new.module').then( m => m.NewPageModule)
  // },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
